import React from 'react';
import './CardPage.css';
import Header from '../Component/PageHeader/Header';
import BankIcon from '../Component/Icons/BankIcon';
import ArrowRightIcon from '../Component/Icons/ArrowRightIcon';

const CardsPage = () => {
  return (
    <div className="page-container">
      <div className="page-content">
        
        <Header
            title= 'Cards'
            className='page-header'
        />
        
        <div className="content-section">
          <a className="add-card-link" href="cards/add-card">
            <div className="card-info">
              <BankIcon
               className="bank-icon"
              />
              <div className="text-content">
                <p>Add a new card</p>
                <p className="subtitle">We support Mastercard, Visa and Verve.</p>
              </div>
            </div>
            <ArrowRightIcon
            />
          </a>
          
          <div className="cards-list">
            <p className="no-cards-message">You have no cards</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardsPage;