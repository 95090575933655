import React from 'react';
import './EmailAddress.css';
import Header from '../../PageHeader/Header';
import EmailIcon from '../../Icons/EmailIcon';
import Button from '../../Buttons/Button/Button';
import { useNavigate } from 'react-router-dom';

const EmailAddress = ({ email='a************o@*****.com' }) => {
  const navigate = useNavigate();

  const onChangeEmail = () =>{
    navigate('email-otp');
  }
  return (
    <div className="EmailPage">
      <div className='email-header'>
        <Header title= 'Email Address' />
      </div>

      <div className="content">
        <div className="email-section">
          <div className="icon-container">
            <EmailIcon />
          </div>
          <div className="details">
            <h2>{email}</h2>
            <p>We use your email address for security and to confirm who you are.</p>
           
             <Button
                type="button" 
                onClick={onChangeEmail} 
                className="submit-button" 
              >
               Change Email Address
              </Button>
          </div>
        </div>
      </div>
    </div>
  );
};


export default EmailAddress;