import React from "react";
import "./CountrySelect.css";

const CountrySelect = () => {
  return (
    <div className="country-select">
      <select className="country-dropdown">
        <option value="234">🇳🇬 NG (+234)</option>
        <option value="1">🇺🇸 USA (+1)</option>
        <option value="44">🇬🇧 UK (+44)</option>
        {/* Add more options as needed */}
      </select>
    </div>
  );
};

export default CountrySelect;
