import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ConfirmPhoneNumber.css";
import Header from "../../PageHeader/Header";
import ErrorAlert from "../../ErrorAlert/ErrorAlert";
import Button from "../../Buttons/Button/Button";

const ConfirmPhoneNumber = () => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [error, setError] = useState("");
  const [resendTimer, setResendTimer] = useState(46);
  const navigate = useNavigate();

  const handleOtpChange = (index, value) => {
    if (!/^\d*$/.test(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value !== "" && index < otp.length - 1) {
      document.getElementById(`otp-${index + 1}`).focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && otp[index] === "") {
      if (index > 0) {
        document.getElementById(`otp-${index - 1}`).focus();
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (otp.some((digit) => digit === "")) {
      setError("Please complete the verification code.");
    } else {
      setError("");
      navigate("/next-page");
    }
  };

  return (
    <div className="container">
      <div className="full-page">
        <div className="content-wrapper">
          <Header title="Confirm New Phone Number" className="header-title" />
          <div className="form-container">
            <h1 className="form-title">Confirm New Phone Number</h1>
            <p className="form-description">We sent a code to 07084260117</p>
            <form onSubmit={handleSubmit}>
              <div className="otp-container">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    id={`otp-${index}`}
                    type="tel"
                    maxLength="1"
                    className="otp-input"
                    value={digit}
                    onChange={(e) => handleOtpChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    aria-label={`Digit ${index + 1}`}
                    autoComplete="off"
                  />
                ))}
              </div>
              <p className="resend-timer">
                Resend Code in <span className="highlight">{resendTimer}</span>s
              </p>
              {error && <ErrorAlert message={error} />}
              
              <Button
                type="submit" 
                onClick={handleSubmit}
                variant="primary"  
                className="submit-button"
              >
                Next
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPhoneNumber;
