import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';
import Header from '../PageHeader/Header';
import ProfileField from './ProfileField/ProfileField';
import ClickableProfileField from './ClickableProfileField/ClickableProfileField';
import './Profile.css';

const userProfile = {
  firstName: 'Adeogo',
  lastName: 'Oladipo',
  dateOfBirth: 'Invalid Date',
  username: '@adeogooladipo',
  phoneNumber: '+2347072032625',
  email: 'adeogo.oladipo@gmail.com',
  nextOfKin: 'Olukayode Oladipo',
  additionalInfo: '0/5',
};

const Profile = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="profile-page">
      <Header title="My Profile" />
      
      <div className="profile-content">
        <div className="profile-picture-container">
          <div className="profile-picture-wrapper">
            <img
              src="/tradetrackr/placeholder-img.png"
              alt="profile picture"
              className="profile-picture"
            />
            <span className="profile-emoji">🔥</span>
          </div>
          <span className="profile-picture-hint">Click to change photo</span>
          <button className="profile-picture-button">
            <span className="sr-only">change profile picture</span>
          </button>
        </div>
        
        <div className="profile-info">
          <ProfileField label="First Name" value={userProfile.firstName} />
          <ProfileField label="Last Name" value={userProfile.lastName} />
          <ProfileField label="Date of birth" value={userProfile.dateOfBirth} />
          <ClickableProfileField 
            label="Username" 
            value={userProfile.username} 
            onClick={() => handleNavigation('username')}
          />
        </div>
        
        <span className="help-text">
          If you have any issues with your information, please send a message to hello@rise.capital
        </span>
        
        <div className="contact-section">
          <h1>CONTACT INFORMATION</h1>
          <div className="contact-fields">
            <ClickableProfileField 
              label="Phone number" 
              value={userProfile.phoneNumber}
              onClick={() => handleNavigation('phone')}
            />
            <ClickableProfileField 
              label="Email address" 
              value={userProfile.email}
              onClick={() => handleNavigation('email')}
            />
            <ClickableProfileField 
              label="Next of kin" 
              value={userProfile.nextOfKin}
              onClick={() => handleNavigation('next-of-kin')}
            />
          </div>
        </div>
        
        <div className="more-info-section">
          <h1>MORE INFORMATION</h1>
          <span className="help-text">
            We need this to provide you with a more customized experience. Filling this out is optional.
          </span>
          <ClickableProfileField 
            label="More information" 
            value={userProfile.additionalInfo}
            onClick={() => handleNavigation('additional-info')}
          />
        </div>
      </div>
    </div>
  );
};

export default Profile;
