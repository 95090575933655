import React from 'react';
import './PhoneNumber.css';
import Header from '../../PageHeader/Header';
import { useNavigate } from 'react-router-dom';

const PhoneNumber = ({ phoneNumber ='+2347072032625', onBack, onChangeNumber }) => {
  const navigate = useNavigate();

  const handleChange = ()=>{
    navigate('confirm-password');
  }
  return (
  
      <div className="content-wrapper2">
         <Header
          title="Phone Number"
          className='phone-header'
        />


        <div className="content">
          <div className="phone-section">
            <div className="icon-container">
              <p>#</p>
            </div>
            <div className="details">
              <h2>{phoneNumber}</h2>
              <p>We use your phone number for security and to confirm who you are.</p>
              <button onClick={handleChange} type="button">
                Change Phone Number
              </button>
            </div>
          </div>
        </div>
      </div>
  );
};

export default PhoneNumber;
