import React from "react";
import "./EmailConfirmation.css";
import Header from "../../../PageHeader/Header";
import EmailIcon from "../../../Icons/EmailIcon";
import ErrorIcon from "../../../Icons/ErrorIcon";
import OTPInput from "../../../OTPInput/OTPInput";

const EmailConfirmation = () => {
  const handleOTPChange = (value, idx) => {
    console.log(`Value at index ${idx}:`, value); // Handle OTP change logic here
  };

  return (
    <div className="content-wrapper">
      <Header title="Change your Email Address" />

      <div className="circular-container">
        <EmailIcon className="email-icon" />
      </div>

      <h1 className="main-title">Confirm your current email address.</h1>
      <p className="description">
        We just emailed a one-time password to adeogo.oladipo@gmail.com. Please type it in.
      </p>

      <div className="otp-section">
        <OTPInput length={6} onChange={handleOTPChange} />
        <button className="resend-code">Resend code</button>
      </div>

      <div className="alert error-alert" aria-hidden="true" role="alert">
        <div className="alert-icon">
          <ErrorIcon />
        </div>
        <div className="alert-message"></div>
      </div>
    </div>
  );
};

export default EmailConfirmation;
