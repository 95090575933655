import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ImageIcon from '../Icons/ImageIcon';
import DeleteIcon from '../Icons/DeleteIcon';
import './OptionalModal.css';
import DeleteModal from './DeleteModal/DeleteModal';
import CloseButton from '../Buttons/CloseButton';
import Button from '../Buttons/Button/Button';

const OptionsModal = ({ onClose, onDeleteClick }) => {
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    const handleDeleteConfirm = () => {
        if (onDeleteClick) onDeleteClick();
        setDeleteModalOpen(false);
        onClose();
    };

    return (
        <div className="options-modal" tabIndex="-1">
            <div className="modal-content">
                <div className="modal-header">
                     <CloseButton
                        onClick={onClose} 
                        className="close-button" 
                     />
                    <h2>Other Options</h2>
                </div>

                <div className="modal-body">
                    <Link
                        to="personalize"
                        className="option-item"
                        onClick={onClose}
                    >
                        <div className="icon-container">
                            <ImageIcon />
                        </div>
                        <span>Personalize Plan</span>
                    </Link>

                     <Button
                        type="button" 
                        onClick={() => setDeleteModalOpen(true)}
                        className="option-item delete"
                        icon={DeleteIcon}
                    >
                        Delete Plan
                    </Button>
                </div>
            </div>
            {isDeleteModalOpen && (
                <DeleteModal
                    onClose={() => setDeleteModalOpen(false)}
                    onDelete={handleDeleteConfirm}
                />
            )}
        </div>
    );
};

export default OptionsModal;