export const COUNTRIES = [
  { code: 'NG', name: 'Nigeria', dialCode: '+234' },
  { code: 'GH', name: 'Ghana', dialCode: '+233' },
  { code: 'KE', name: 'Kenya', dialCode: '+254' },
  { code: 'ZA', name: 'South Africa', dialCode: '+27' },
  { code: 'UG', name: 'Uganda', dialCode: '+256' },
  { code: 'TZ', name: 'Tanzania', dialCode: '+255' },
];

export const BANKS = [
  { id: '1', name: 'ACCESS BANK NIGERIA' },
  { id: '2', name: 'FIRST BANK OF NIGERIA' },
  { id: '3', name: 'GUARANTY TRUST BANK' },
  { id: '4', name: 'UNITED BANK FOR AFRICA' },
  { id: '5', name: 'ZENITH BANK' },
  { id: '6', name: 'FIDELITY BANK' },
  { id: '7', name: 'STERLING BANK' },
];

export const RELATIONSHIPS = [
  'Parent',
  'Spouse',
  'Child',
  'Sibling',
  'Other Family Member',
  'Friend',
];