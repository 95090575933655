import React, { useState } from 'react';
import './RemadeRealEstatePlan.css';
import PerformanceChart from '../PerformanceChart/PerformanceChart';
import { ChevronRightIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useBalanceVisibility } from '../BalanceVisibilityContext/BalanceVisibilityContext';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import KeyboardArrowRightIcon from '../Icons/KeyboardArrowRightIcon';
import CallReceivedIcon from '../Icons/CallReceivedIcon';
import PageHeader from '../PageHeader/PageHeader';

const RemadeRealEstatePlan = () => {
const navigate = useNavigate();
const { isBalanceHidden } = useBalanceVisibility();
const [isAutoInvestEnabled, setIsAutoInvestEnabled] = useState(false);
const handleFundPlan = () => {
  navigate('/trackr-home/plans/fund-wallet');
};

const handleWithdraw = () => {
  navigate('/trackr-home/plans/withdraw-wallet');
};

 const handleAutoInvestClick = () => {
    navigate('auto-invest');
  };


  return (
    <div className="remade-real-estate-plan">
      <PageHeader
        title="Remade"
        subtitle="Real Estate Plan"
        showOptions={true}
        backgroundImage="https://res.cloudinary.com/dsjxfieim/image/upload/v1634304447/Plan%20Covers/Untitled_tsju4k.jpg"
      />

      <div className="content">
        <div className="plan-balance">
          <p>Plan Balance</p>
          <h3>{isBalanceHidden ? '******' : '$10,000.00'}</h3>
          <p className="masked-balance">******</p>
        </div>

        <div className="gains">
          <p>Gains</p>
          <p className="success">+$0.00 • +0.00%</p>
        </div>

        <div className="goal-progress">
          <div className="progress-labels">
            <p>Infinity% achieved</p>
            <p>Target : $0.00</p>
          </div>
          <div className="progress-bar">
            <div className="progress"></div>
          </div>
        </div>

        <div className="actions">
          <button className="fund-plan" onClick={handleFundPlan}>
            <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24">
              <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6z"></path>
            </svg>
            Fund Plan
          </button>
          <button className="withdraw" onClick={handleWithdraw}>
            <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24">
              <path d="M19 13H5v-2h14z"></path>
            </svg>
            Withdraw
          </button>
        </div>

        <div 
          className="auto-invest"
          onClick={handleAutoInvestClick}
          style={{ cursor: 'pointer' }}
          role="button"
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              handleAutoInvestClick();
            }
          }}
        >
          <span>Auto-Invest</span>
          <div className="right-content">
            <div className="chip">Turn On</div>
            <ChevronRightIcon />
          </div>
        </div>

      <div className="reinvest">
        <span>Reinvest After Maturity</span>
         <ToggleSwitch
              checked={isAutoInvestEnabled}
              onChange={(e) => setIsAutoInvestEnabled(e.target.checked)}
        />
      </div>
          
          <PerformanceChart />
       

        <div className="performance-summary">
          <h3>Performance Summary</h3>
          <div className="summary-item">
            <span>Plan Created On</span>
            <span>13th September, 2024</span>
          </div>
          <div className="summary-item">
            <span>Expected Annual Return</span>
            <span className="success">0.00%</span>
          </div>
          <div className="summary-item">
            <span>Total deposit</span>
            <span>$76.63</span>
          </div>
          <div className="summary-item">
            <span>Total Earnings</span>
            <span>$0.00</span>
          </div>
          <div className="summary-item">
            <span>Recent Earnings</span>
            <span>$0.00</span>
          </div>
          <div className="summary-item">
            <span>Total Withdrawals</span>
            <span>$0.00</span>
          </div>
          <div className="summary-item">
            <span>Plan Maturity Date</span>
            <span>13th September, 2025</span>
          </div>
        </div>

        <div className="recent-transactions">
          <div className="transactions-header">
            <h2>Recent Transactions</h2>
            <a href="/plans/4cef4d0b-fe8b-45eb-a26c-8dd5bc08413d/transactions">
              <span>View all</span>
              <KeyboardArrowRightIcon />
            </a>
          </div>
          <div className="transaction-item">
            <div className="transaction-icon">
              <CallReceivedIcon />
            </div>
            <div className="transaction-details">
              <p>Transfer to Plan(Remade)</p>
              <p>Sep 13, 2024</p>
            </div>
            <span className="transaction-amount">+ $76.63</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemadeRealEstatePlan;