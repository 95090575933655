import React, { useState } from 'react';
import { KeyboardArrowLeft, AlertCircle } from 'lucide-react';
import './DeleteModal.css';
import Header from '../../PageHeader/Header';
import Button from '../../Buttons/Button/Button';

const DeleteModal = ({ onClose, onConfirm }) => {
  const [error, setError] = useState('');

  return (
    <div className="delete-modal" tabIndex="-1">
      <div className="delete-modal-content">
       
          <Header
            title= 'Delete Plan'
            className='modal-header'
          />

        <div className="modal-body">
          <p>Are you sure you want to delete this plan?</p>
          
          {error && (
            <div className="error-alert" role="alert">
              <AlertCircle />
              <div className="error-message">{error}</div>
            </div>
          )}

          <div className="button-group">
            <Button
                type="button" 
                onClick={onClose}
                variant="primary"  
                className="cancel-button"
            >
                No
            </Button>

            <Button
                type="button" 
                onClick={onConfirm}
                variant="primary"  
                className="delete-button"
            >
              Yes
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
