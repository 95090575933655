import React, { useState } from 'react';
import './ResetPin.css';
import Header from '../PageHeader/Header';
import ErrorIcon from '../Icons/ErrorIcon';
import EyeIcon from '../Icons/EyeIcon';
import Button from '../Buttons/Button/Button';

const ResetPin = () => {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError('');
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!password) {
      setError('Password is required');
    }
  };

  return (
    <div className="reset-pin-container">
      <Header
        title='Reset PIN'
        className="reset-header"
      />

      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <p className="instruction-text">
            Enter your password to reset your Rise PIN
          </p>

          <div className="password-input">
            <div className="input-wrapper">
              <input
                id="password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={handlePasswordChange}
                placeholder=" "
              />
              <label 
                htmlFor="password" 
                className="floating-label"
              >
                Password
              </label>

              <Button 
                type="button" 
                className="toggle-visibility"
                onClick={togglePasswordVisibility}
              >
                   <EyeIcon isSlashed={showPassword} />
                <span className="sr-only">Show Password</span>
              </Button>
            </div>
          </div>

          {error && (
            <div className={`error-alert ${error ? 'show' : ''}`}>
              <span className="error-icon">
                <ErrorIcon />
              </span>
              {error}
            </div>
          )}

          <button 
            type="submit" 
            className="reset-button"
            disabled={!password}
          >
            Reset PIN
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPin;