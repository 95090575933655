import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../PageHeader/Header';
import CurrencyInput from '../CurrencyInput/CurrencyInput';
import './CurrencyInputForm.css';
import CloseIcon2 from '../Icons/CloseIcon2';
import Button from '../Buttons/Button/Button';


const CurrencyInputForm = ({ onBack }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [nairaAmount, setNairaAmount] = useState('0.00');
  const [dollarAmount, setDollarAmount] = useState('0');
  const [showRateInfo, setShowRateInfo] = useState(false);
  const conversionRate = 1767;

  useEffect(() => {
    const currentAmount = location.state?.currentAmount || 0;
    if (currentAmount > 0) {
      setDollarAmount(currentAmount.toString());
      setNairaAmount((currentAmount * conversionRate).toFixed(2));
    }
  }, [location.state, conversionRate]);

  const handleNairaChange = (e) => {
    const value = e.target.value.replace(/[^\d.]/g, '');
    setNairaAmount(value);
    const dollars = (parseFloat(value) / conversionRate).toFixed(2);
    setDollarAmount(isNaN(dollars) ? '0' : dollars);
  };

  const handleDollarChange = (e) => {
    const value = e.target.value.replace(/[^\d.]/g, '');
    setDollarAmount(value);
    const naira = (parseFloat(value) * conversionRate).toFixed(2);
    setNairaAmount(isNaN(naira) ? '0.00' : naira);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const amount = parseFloat(dollarAmount);
    localStorage.setItem('autoInvestAmount', amount.toString());
    navigate(-1);
  };

  const handleInfoClick = () => {
    setShowRateInfo(true);
  };

  const handleCloseModal = () => {
    setShowRateInfo(false);
  };

  return (
    <div className="currency-form">
      <Header title="Enter amount" />
      <div className="currency-form__container">
        <form onSubmit={handleSubmit}>
          <div className="currency-form__inputs">
            <CurrencyInput
              symbol="₦"
              value={nairaAmount}
              onChange={handleNairaChange}
              id="naira-amount"
            />

            <div className="currency-form__rate-container">
              <div className="currency-form__rate">
                <div className="currency-form__rate-text">
                  $1 = ₦{conversionRate}
                </div>
                <button 
                  type="button"
                  className="currency-form__info-button"
                  onClick={handleInfoClick}
                >
                  Why this rate?
                </button>
              </div>
            </div>

            <CurrencyInput
              symbol="$"
              value={dollarAmount}
              onChange={handleDollarChange}
              id="dollar-amount"
            />
          </div>

          <div className="currency-form__submit">

            <Button
              type="submit" 
              onClick={handleSubmit}
              variant="primary"  
              className="primary" 
              disabled={parseFloat(dollarAmount) <= 0}
            >
              Save
            </Button>

          </div>
        </form>

        {showRateInfo && (
              <>
                <div className="modal-overlay" onClick={handleCloseModal} />
                <div 
                  className="modal"
                  tabIndex="-1"
                >
                  <div 
                    data-test-id="exchange-rates-modal"
                    className="modal__content"
                  >
                    <div className="modal__header">
                      <button 
                        className="modal__close-button"
                        onClick={handleCloseModal}
                        type="button"
                      >
                        <span className="sr-only">Close</span>
                        <CloseIcon2 />
                      </button>
                      <h2 className="modal__title">About Exchange Rates</h2>
                    </div>

                  </div>
                </div>
              </>
            )}
      </div>
    </div>
  );
};

export default CurrencyInputForm;