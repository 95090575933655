import React, { useState, useEffect } from 'react';
import { Button, Modal, Tag } from 'antd';
import {
  PlusOutlined,
  MinusOutlined,
  RightOutlined,
  CloseOutlined
} from '@ant-design/icons';
import './FixedIncomePlan.css';
import { Link, useNavigate } from 'react-router-dom';
import { useBalanceVisibility } from '../BalanceVisibilityContext/BalanceVisibilityContext';
import PageHeader from '../PageHeader/PageHeader';



const FixedIncomePlan = () => {
  const navigate  = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(true);
  const { isBalanceHidden, toggleBalanceVisibility } = useBalanceVisibility();
  
 
  useEffect(() => {
    
  }, []);

 
  const handleFundPlan = () => {
  navigate('/trackr-home/plans/fund-wallet');
};

const handleWithdraw = () => {
  navigate('/trackr-home/plans/withdraw-wallet');
};

 
  const handleOk = () => {
    setIsModalVisible(false);
    navigate('/trackr-home/plans/fund-wallet');
  };

 
  const handleCancel = () => setIsModalVisible(false);

  return (
    <div className="fixed-income-plan">
      <PageHeader
        title="003"
        subtitle="Fixed Income Plan"
        showOptions={true}
        backgroundImage="https://res.cloudinary.com/dsjxfieim/image/upload/v1634304182/Plan%20Covers/Untitled_1_1_ysepko.jpg"
      />

      <div className="plan-content">
        <div className="plan-balance">
          <p className="text-soft">Plan Balance</p>
          <h2 data-test-id="plan-balance">{isBalanceHidden ? '******' : '$0.00'}</h2>
          <p className="balance-mask" data-test-id="plan-balance-mask">******</p>
        </div>

        <div className="plan-gains">
          <p className="text-soft">Gains</p>
          <p className="gains-value">+$<span data-test-id="current-earning">0.00</span> • +0.00%</p>
        </div>

        
        <p className="returns-update">Returns are updated daily</p>

        
           <div className="button-container">
          <button className="fund-button" onClick={handleFundPlan}>
            <PlusOutlined />
            Fund Plan
          </button>
          <button className="withdraw-button" onClick={handleWithdraw}>
            <MinusOutlined />
            Withdraw
          </button>
        </div>



        <div className="auto-invest">
          <Link to ={'auto-invest'}>
            <span>Auto-Invest</span>
            <div className="auto-invest-status">
              <Tag color="warning">Turn On</Tag>
              <RightOutlined />
            </div>
          </Link>
        </div>

        
        <div className="performance-summary">
          <p className="summary-title">Performance Summary</p>
          <div className="summary-content">
            <SummaryItem label="Plan Created On" value="11th September, 2024" testId="plan-created-on" />
            <SummaryItem label="Expected Annual Return" value="10.00%" testId="expected-annual-return" className="text-success" />
            <SummaryItem label="Total deposit" value="$0.00" testId="total-deposit-amount" />
            <SummaryItem label="Total Earnings" value="$0.00" testId="total-earnings-amount" />
            <SummaryItem label="Recent Earnings" value="$0.00" testId="current-earnings-amount" />
            <SummaryItem label="Total Withdrawals" value="$0.00" testId="total-withdrawals-amount" />
          </div>
        </div>
      </div>

      
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closeIcon={<CloseOutlined />}
        wrapClassName="fund-plan-modal-wrapper"
        centered
      >
        <div className="fund-plan-modal">
          <h2>Would you like to add money now?</h2>
          <p>You currently are low on Plan funds. Would you like to <br/> fund your plan?</p>
          <div className="modal-buttons">
            <Button type="primary" size="large" block onClick={handleOk}>
              Yes, add money now.
            </Button>
            <Button size="large" block onClick={handleCancel}>
              No, later
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};


const SummaryItem = ({ label, value, testId, className }) => (
  <div className="summary-item">
    <span className="text-soft">{label}</span>
    <span data-test-id={testId} className={className}>{value}</span>
  </div>
);

export default FixedIncomePlan;