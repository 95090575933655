import React, { useState } from 'react';
import './UsernameChange.css';
import Header from '../../PageHeader/Header';


const UsernameChange = ({ onBack }) => {
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');
  const [serverError, setServerError] = useState('');

  const handleSubmit = () => {
  
  };

  return (
    <div className="grid">
      <div className="container">
        <div className="content">
          <div className="inner-content">
            <Header
              title="Change username"
            />

            <div className="form-section">
              <p className="helper-text">
                Give yourself a username with no spaces in-between<br />
                You will be addressed by this username when using the Rise app
              </p>

              <div className="input-wrapper">
                <div className="input-container">
                  <span className="at-symbol">@</span>
                  <input
                    type="text"
                    placeholder="adeogooladipo"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="username-input"
                  />
                </div>
              </div>

              {error && <p className="error-text">{error}</p>}

              {serverError && (
                <div className="alert error" role="alert">
                  <span className="alert-icon">!</span>
                  <span>{serverError}</span>
                </div>
              )}

              <button
                className="submit-button"
                type="button"
                disabled={!username}
                onClick={handleSubmit}
              >
                Set Username
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsernameChange;