import React from 'react';
import './ProfileField.css';

const ProfileField = ({ label, value }) => {
  return (
    <div className="profile-field">
      <p className="field-label">{label}</p>
      <p>{value}</p>
    </div>
  );
};

export default ProfileField;