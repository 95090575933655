import React from 'react';
import './ConfirmationModal.css';
import { CloseButton } from '../Buttons';
import Button from '../Buttons/Button/Button';

const ConfirmationModal = ({ onClose, onConfirm }) => {
    
    return (
        <div 
            className="confirmation-modal"
            data-test-id="confirmation-modal"
            tabIndex="-1"
        >
            <div className="modal-content">
                <div className="modal-header">
                    <CloseButton onClick={onClose} />
                    <h2>Confirm creation</h2>
                </div>

                <div className="modal-body">
                    <p>Stocks are volatile and your investments may sometimes fall below the amount you invested.</p>
                    <p>Do you still wish to continue?</p>
                </div>

                <div className="modal-actions">

                   <Button 
                    type="button" 
                    onClick={onConfirm} 
                    variant="primary" 
                    className="primary"
                    >
                    Proceed with Payment
                    </Button>

                   <Button
                    type="button" 
                    onClick={onClose}
                    variant="primary"  
                    className="cancel"
                    >
                    Back to Cart
                    </Button>

                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;