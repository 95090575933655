import React from "react";

const ArrowRightIconPrimary = ({ className = "" }) => (
  <svg
    className={`icon ${className}`}
    focusable="false"
    aria-hidden="true"
    viewBox="0 0 24 24"
  >
    <path d="M8.59 16.59 13.17 12 8.59 7.41 10 6l6 6-6 6z"></path>
  </svg>
);

export default ArrowRightIconPrimary;
