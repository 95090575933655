import React, { useRef } from 'react';
import './ResetPassword.css';
import ErrorIcon from '../Icons/ErrorIcon';
import Header from '../PageHeader/Header';

const ResetPassword = () => {
  const inputRefs = useRef([]);

  const handleInputChange = (e, index) => {
    const value = e.target.value;

    // Only allow numbers
    if (!/^\d?$/.test(value)) {
      e.target.value = ''; // Clear invalid input
      return;
    }

    // Move to the next input field if a number is entered
    if (value && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }

    // Clear the input field if backspace is pressed
    if (!value && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <div className="reset-password-container">
      <div className="grid-container">
        <Header title="Reset Password" className="header" />
        <h1 className="sub-title">Confirm your email address</h1>
        <p className="desc">We just emailed you a one-time password. Please, type it in</p>
        <div className="otp-container">
          {Array(6)
            .fill(0)
            .map((_, index) => (
              <input
                key={index}
                ref={(el) => (inputRefs.current[index] = el)} // Store input refs
                aria-label={`Digit ${index + 1}`}
                className="otp-input"
                type="tel"
                maxLength="1"
                autoComplete="off"
                onChange={(e) => handleInputChange(e, index)}
              />
            ))}
        </div>
        <div className="resend-container">
          <p>
            Resend Code in <span className="timer">06</span>s
          </p>
        </div>
        <div className="alert hidden" role="alert">
          <div className="icon">
            <ErrorIcon />
          </div>
          <div className="message">Error message goes here</div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
