import React, { useState } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import './plans.css';
import CustomCard from '../CustomCard/CustomCard';
import PortfolioComponent from '../PortfolioPage/PortfolioComponent';
import BuildWealth from '../BuildWealth/BuildWealth';
import StockPlan from '../StockPlan/StockPlan';
import StartBusinessPlan from '../StartBusinessPlan/StartBusinessPlan';
import { DynamicPlanRouter } from '../DynamicPlanRouter/DynamicPlanRouter';
import SeeOurPortfolio from '../SeeOurPortfolio/SeeOurPortfolio';
import { BalanceVisibilityProvider } from '../BalanceVisibilityContext/BalanceVisibilityContext';
import AddMoneyPlan from '../AddMoneyplan/AddMoneyPlan';
import WithdrawWallet from '../WithdrawWallet/WithdrawWallet';
import PlansContent from '../PlansContent/PlansContent';
import MaturedPlans from '../MaturedPlans/MaturedPlans';
import ViewAllPlans from '../ViewAllPlans/ViewAllPlans';
import PlanNameForm from '../PlansNameForm/PlanNameForm';
import AutoInvest from '../AutoInvest/AutoInvest';
import BuildCurrencySelector from '../BuildCurrencySelector/BuildCurrencySelector';
import IncomeForm from '../IncomeForm/IncomeForm';
import SavingsForm from '../SavingsForm/SavingsForm';
import RetirementAgeForm from '../RetirementAgeForm/RetirementAgeForm';
import PreferencesForm from '../PreferencesForm/PreferencesForm';
import PortfolioView from '../PortfolioView/PortfolioView';
import ProjectionView from '../ProjectionView/ProjectionView';
import PortfolioEditor from '../PortfolioEditor/PortfolioEditor';
import PlanReview from '../PlanReview/PlanReview';
import SuccessView from '../SuccessView/SuccessView';
import DurationSelector from '../DurationSelector/DurationSelector';
import PersonalizeForm from '../OptionalModal/PersonalizeForm/PersonalizeForm';
import CurrencyInputForm from '../CurrencyInputForm/CurrencyInputForm';

const PlansPage = () => {
  const [activeTab, setActiveTab] = useState('plans');
  const location = useLocation();
  const navigate = useNavigate();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (tab === 'portfolio') {
      navigate('/trackr-home/plans/portfolio');
    } else {
      navigate('/trackr-home/plans');
    }
  };

  const plans = [
    { id: '549875', name: '003', balance: '$0.00', description: 'Fixed Income Plan', image: 'https://res.cloudinary.com/dsjxfieim/image/upload/v1634304182/Plan%20Covers/Untitled_1_1_ysepko.jpg' },
    { id: '549874', name: '002', balance: '$0.00', description: 'Stocks Plan', image: 'https://res.cloudinary.com/dsjxfieim/image/upload/v1634304409/Plan%20Covers/Untitled_2_ox4b5h.jpg' },
    { id: '540593', name: 'Smurts01', balance: '$0.00', description: 'Real Estate Plan Plan', image: 'https://res.cloudinary.com/dsjxfieim/image/upload/v1634304447/Plan%20Covers/Untitled_tsju4k.jpg' },
  ];

  const assetClasses = [
    { id: '1', name: 'Stocks', risk: 'high', image: 'https://res.cloudinary.com/dsjxfieim/image/upload/v1634304182/Plan%20Covers/Untitled_1_1_ysepko.jpg' },
    { id: '2', name: 'Real Estate', risk: 'medium', image: 'https://res.cloudinary.com/dsjxfieim/image/upload/v1634304409/Plan%20Covers/Untitled_2_ox4b5h.jpg' },
    { id: '4', name: 'Fixed Income', risk: 'low', image: 'https://res.cloudinary.com/dsjxfieim/image/upload/v1634304447/Plan%20Covers/Untitled_tsju4k.jpg' },
  ];

  const goals = [
    { id: '1', name: 'Start a Business', image: 'https://res.cloudinary.com/dsjxfieim/image/upload/c_scale,w_600/v1634304112/Plan%20Covers/Untitled_1_guke37.jpg' },
    { id: '2', name: 'Save for School', image: 'https://res.cloudinary.com/dsjxfieim/image/upload/c_scale,w_600/v1634303708/Plan%20Covers/Untitled_hada8r.jpg' },
    { id: '3', name: 'Plan a Wedding', image: 'https://res.cloudinary.com/dsjxfieim/image/upload/c_scale,w_600/v1634304572/Plan%20Covers/Untitled_x6iipw.jpg' },
    { id: '4', name: 'Travel', image: 'https://res.cloudinary.com/dsjxfieim/image/upload/c_scale,w_600/v1634304635/Plan%20Covers/Untitled_1_1_mhsgpl.jpg' },
    { id: '5', name: 'Own Your Own Home', image: 'https://res.cloudinary.com/dsjxfieim/image/upload/c_scale,w_600/v1634304320/Plan%20Covers/Untitled_1_lt8dik.jpg' },
    { id: '6', name: 'Kids', image: 'https://res.cloudinary.com/dsjxfieim/image/upload/c_scale,w_600/v1634304265/Plan%20Covers/Untitled_zpfbas.jpg' },
    { id: '7', name: 'Save for Rent', image: 'https://res.cloudinary.com/dsjxfieim/image/upload/c_scale,w_600/v1634727519/Plan%20Covers/Save_for_rent_1_eon1ca.jpg' },
  ];


  const isNestedRoute = () => {
    const path = location.pathname;
    const nestedRoutes = [
      '/new/',
      '/asset/',
      '/goal/',
      '/fund-wallet',
      '/withdraw-wallet',
      '/matured-plans',
      '/your-plans',
      '/auto-invest',
      '/plan-name',
      '/rise-portfolio',
      '/create-investment-plan',
      '/build-wealth'
    ];
    
   
    return nestedRoutes.some(route => path.includes(route)) || 
           /\/trackr-home\/plans\/\d+/.test(path);
  };

  return (
    <BalanceVisibilityProvider>
      <main className="page-content">
        {!isNestedRoute() && (
          <>
            <header>
              <h1>Plans</h1>
            </header>
            
            <div className="tabs">
              <button
                className={`tab ${activeTab === 'plans' ? 'active' : ''}`}
                onClick={() => handleTabChange('plans')}
              >
                Plans
              </button>
              <button
                className={`tab ${activeTab === 'portfolio' ? 'active' : ''}`}
                onClick={() => handleTabChange('portfolio')}
              >
                Portfolio
              </button>
            </div>
          </>
        )}
        
        <Routes>
          <Route 
            index 
            element={<PlansContent plans={plans} assetClasses={assetClasses} goals={goals} />} 
          />
          <Route path="portfolio" element={<PortfolioComponent />} />
          <Route path=":id" element={<DynamicPlanRouter />} />
          <Route path=":id/personalize" element={<PersonalizeForm />} />
          <Route path=":id/auto-invest" element={<AutoInvest />} />
          <Route path=":id/auto-invest/tap-to-edit" element={<CurrencyInputForm />} />
          <Route path=":id/plan-name" element={<PlanNameForm />} />
          <Route path="new/:type" element={<BuildWealth />} />
          <Route path="create-investment-plan/:type" element={<BuildWealth />} />
          <Route path="create-investment-plan/:type/start" element={<BuildCurrencySelector/>} />
          <Route path="create-investment-plan/:type/start/build-currency" element={<PlanNameForm/>} />
          <Route path="create-investment-plan/:type/start/build-currency/income-form" element={<IncomeForm/>} />
          <Route path="create-investment-plan/:type/start/build-currency/income-form/savings-form" element={<SavingsForm />} />
          <Route path="create-investment-plan/:type/start/build-currency/income-form/savings-form/retirement-form" element={<RetirementAgeForm />} />
          <Route path="create-investment-plan/:type/start/build-currency/income-form/savings-form/retirement-form/preferences-form" element={<PreferencesForm />} />
          <Route path="create-investment-plan/:type/start/build-currency/income-form/savings-form/retirement-form/preferences-form/portfolio-view" element={<PortfolioView />} />
          <Route path="create-investment-plan/:type/start/build-currency/income-form/savings-form/retirement-form/preferences-form/portfolio-view/review" element={<ProjectionView />} />
          <Route path="create-investment-plan/:type/start/build-currency/income-form/savings-form/retirement-form/preferences-form/portfolio-view/edit" element={<PortfolioEditor />} />
          <Route path="create-investment-plan/:type/start/build-currency/income-form/savings-form/retirement-form/preferences-form/portfolio-view/edit/save-portfolio" element={<PortfolioView />} />
          <Route path="create-investment-plan/:type/start/build-currency/income-form/savings-form/retirement-form/preferences-form/portfolio-view/review/portfolio-editor" element={<PlanReview />} />
          <Route path="create-investment-plan/:type/start/build-currency/income-form/savings-form/retirement-form/preferences-form/portfolio-view/review/portfolio-editor/success" element={<SuccessView />} />
          <Route path="create-investment-plan/asset/:id" element={<StockPlan />} />
          <Route path="create-investment-plan/asset/:id/add" element={<PlanNameForm />} />
          <Route path="create-investment-plan/asset/:id/add/income-form" element={<DurationSelector />} />
          <Route path="create-investment-plan/asset/:id/add/income-form/success" element={<SuccessView />} />
          <Route path="asset/:id" element={<StockPlan />} />
          <Route path="asset/:id/add" element={<PlanNameForm />} />
          <Route path="asset/:id/add/income-form" element={<DurationSelector />} />
          <Route path="asset/:id/add/income-form/success" element={<SuccessView />} />
          <Route path="goal/:id" element={<StartBusinessPlan />} />
          <Route path="create-investment-plan/goal/:id" element={<StartBusinessPlan />} />
          <Route path="create-investment-plan/goal/:id/plan-name" element={<PlanNameForm />} />
          <Route path="create-investment-plan/goal/:id/plan-name/income-form" element={<BuildCurrencySelector />} />
          <Route path="rise-portfolio" element={<SeeOurPortfolio />} />
          <Route path="fund-wallet" element={<AddMoneyPlan />} />
          <Route path="withdraw-wallet" element={<WithdrawWallet />} />
          <Route path="matured-plans" element={<MaturedPlans />} />
          <Route path="your-plans" element={<ViewAllPlans />} />
          <Route 
            path="/create-investment-plan" 
            element={<PlansContent plans={plans} assetClasses={assetClasses} goals={goals} isCreateInvestmentPlan={true} />} 
          />
        </Routes>
      </main>
    </BalanceVisibilityProvider>
  );
};

export default PlansPage;