import React from "react";
import "./BankAccounts.css";
import CircleIcon from "../Icons/CircleIcon";
import Header from "../PageHeader/Header";
import ArrowRightIconPrimary from "../Icons/ArrowRightIconPrimary";
import { useNavigate } from "react-router-dom";

const BankAccounts = () => {
  const navigate = useNavigate();

  const handleChange = () => {
    navigate("add"); 
  };

  return (
    <div className="bank-accounts">
      <div className="content">
        <Header title="Bank Accounts" className="header" />
        <div className="account-list">
          <a id="bank-account" className="account-item" href="accounts/id">
            <div className="account-details">
              <div className="account-main">
                <p className="account-number">1101103627</p>
                <CircleIcon />
                <p className="account-bank">Kuda Bank</p>
              </div>
              <p className="account-holder">ADEOGO EMMANUEL OLADIPO</p>
            </div>
            <ArrowRightIconPrimary className="custom-icon" />
          </a>
        </div>
        <button
          className="add-account-button"
          tabIndex="0"
          type="button"
          onClick={handleChange}
        >
          Add a new bank account
        </button>
      </div>
    </div>
  );
};

export default BankAccounts;
