import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import TrackrDashboardLayout from "../../Component/TrackrDashboardLayout";
import DashboardContent from "../../Component/TrackrAppDashboard/DashboardContent";
import PlansPage from '../../Component/PlansPage/PlansPage';
import FeedPage from '../../Component/FeedPage/FeedPage';
import NotificationsPage from '../../Component/NotificationsPage/NotificationsPage';
import MoreRoute from '../../Component/MoreRoute/MoreRoute';
import WalletRoute from '../../Component/WalletRoute/WalletRoute';
import ArticleDetails from '../../Component/ArticleDetails/ArticleDetails';
import IDVerification from '../../Component/IDVerification/IDVericafication';



const TrackrApp = () => {
  return (
    <TrackrDashboardLayout>
      <Routes>
        <Route index element={<DashboardContent />} />
        <Route path="plans/*" element={<PlansPage />} />
        <Route path="wallet/*" element={<WalletRoute />} />
        <Route path="feed/*" element={<FeedPage />} />
        <Route path=":id" element={<ArticleDetails />} />
        <Route path="more/*" element={<MoreRoute />} />
        <Route path="notifications" element={<NotificationsPage />} />
        <Route path="verification" element={<IDVerification />} />
      </Routes>
    </TrackrDashboardLayout>
  );
};

export default TrackrApp;
