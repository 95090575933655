import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NotificationsPage from '../NotificationsPage/NotificationsPage';
import MorePage from '../MorePage/MorePage';
import AffiliateReferrals from '../Referrals/AffiliateReferrals';
import Faq from '../Faq/Faq';
import ReferralForm from '../ReferralForm/ReferralForm';

import ArticleDetails from '../ArticleDetails/ArticleDetails';

import BanksAndCards from '../BanksAndCards/BanksAndCards';
import CardsPage from '../../CardPage/CardPage';
import BuildCurrencySelector from '../BuildCurrencySelector/BuildCurrencySelector';
import AuthorizationCharge from '../AuthorizationCharge/AuthorizationCharge';
import BankAccounts from '../BankAccounts/BankAccounts';
import BankSelector from '../BankSelector/BankSelector';
import AddBankAccount from '../AddBankAccount/AddBankAccount';
import ProfileBankAccountDetails from '../ProfileBankAccountDetails/ProfileBankAccountDetails';

import Profile from '../Profile/Profile';
import UsernameChange from '../ProfilePages/UsernameChange2/UsernameChange';
import EmailAddress from '../ProfilePages/EmailAddress/EmailAddress';
import AdditionalInfo from '../ProfilePages/AdditionalInfo/AdditionalInfo';
import NextOfKin from '../ProfilePages/NextOfKin/NextOfKin';
import PhoneNumber from '../ProfilePages/PhoneNumber/PhoneNumber';
import TransactionHistory from '../TransactionHistory/TransactionHistory';
import ConfirmWithPassword from '../ProfilePages/ConfirmWithPassword/ConfirmWithPassword';
import ChangePhoneNumber from '../ProfilePages/ChangePhoneNumber/ChangePhoneNumber';
import ConfirmPhoneNumber from '../ProfilePages/ConfirmPhoneNumber/ConfirmPhoneNumber';

import WalletInterest from '../WalletInterest/WalletInterest';
import EmailConfirmation from '../ProfilePages/EmailAddress/Emailcomfirmation/EmailConfirmation';

import ResetPassword from '../ResetPassword/ResetPassword';

import ResetPin from '../ResetPin/ResetPin';
import AccountStatement from '../AccountStatement/AccountStatement';



const MoreRoute = () => {
  return (
    <Routes>
      <Route index element={<MorePage />} />
      <Route path="notifications" element={<NotificationsPage />} />

      <Route path="reset-password" element={<ResetPassword />} />

      <Route path="change-pin" element={<ResetPin />} />
      <Route path="account-statement" element={<AccountStatement />} />

      <Route path="banks-and-cards" element={<BanksAndCards />} />
      <Route path="banks-and-cards/cards" element={<CardsPage />} />
      <Route path="banks-and-cards/accounts" element={<BankAccounts />} />
      <Route path="banks-and-cards/accounts/id" element={<ProfileBankAccountDetails />} />
      <Route path="banks-and-cards/accounts/add" element={<AddBankAccount />} />
      <Route path="banks-and-cards/accounts/add/select-bank" element={<BankSelector />} />
      <Route path="banks-and-cards/cards/add-card" element={<BuildCurrencySelector />} />
      <Route path="banks-and-cards/cards/add-card/build-currency" element={<AuthorizationCharge />} />
      <Route path="transactions" element={<TransactionHistory />} />
      <Route path="wallet-interest" element={<WalletInterest />} />

      <Route path="refer" element={<AffiliateReferrals />} />
      <Route path="profile" element={<Profile />} />
      <Route path="profile/username" element={<UsernameChange />} />
      <Route path="profile/phone" element={<PhoneNumber />} />
      <Route path="profile/phone/confirm-password" element={<ConfirmWithPassword />} />
      <Route path="profile/phone/confirm-password/enter-phone-number" element={<ChangePhoneNumber />} />
      <Route path="profile/phone/confirm-password/enter-phone-number/verify-phone" element={<ConfirmPhoneNumber />} />
      <Route path="profile/next-of-kin" element={<NextOfKin />} />
      <Route path="profile/email" element={<EmailAddress />} />
      <Route path="profile/email/email-otp" element={<EmailConfirmation />} />
      <Route path="profile/additional-info" element={<AdditionalInfo />} />
       <Route path="refer/faq" element={<Faq />} />
       <Route path="refer/referral" element={<ReferralForm />} />
    </Routes>
  );
};

export default MoreRoute;