import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faBell, 
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import './MorePage.css';
import { Link, useNavigate } from 'react-router-dom';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';

const MorePage = () => {
     const navigate = useNavigate();
     const [isAllowNotificationEnabled, setAllowNotificationEnabled] = useState(false);
    const handleNotificationClick = () => {
    navigate('/more/notifications');
  };

  const handleEditClick = () =>{
    navigate('profile');
  }
  return (
    <div className="more-page">
      <div className="header">
        <Link
        to="notifications"
        className="notification-button"
        aria-label="Go to notifications page"
      >
        <FontAwesomeIcon icon={faBell} className="bell-icon" />
        <span className="notification-badge">24</span>
      </Link>
        <h1>More</h1>
        <div className="logout-container">
          <button className="logout-button">Logout</button>
          <FontAwesomeIcon icon={faChevronRight} className="logout-icon" />
        </div>
      </div>

      <div className="profile-section">
        <div className="profile-image"><img src='/tradetrackr/placeholder-img.png'/></div>
        <div className="profile-info">
          <p>Adeogo Oladipo</p>
          <button className="edit-profile-button" onClick={handleEditClick}>Edit Profile</button>
        </div>
        <a href="more/profile" className="profile-link">
          <FontAwesomeIcon icon={faChevronRight} />
        </a>
      </div>

      <h2>Profile</h2>
      <div className="menu-section">
        <a href="verification" className="menu-item">
          <span>ID Verification</span>
          <div className='id-verification'>
            <span className="verification-status">Verified</span>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </a>
        <a href="more/banks-and-cards" className="menu-item">
          <span> Accounts & Cards</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </a>
        <a href="more/transactions" className="menu-item">
          <span> Transactions</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </a>
        <a href="more/wallet-interest" className="menu-item">
          <span> Wallet Interest</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </a>
        <a href="more/account-statement" className="menu-item">
          <span> Account Statement</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </a>
        
         <Link
            to="refer"
            className="menu-item"
         >
          <span> Affiliates & Referrals 🎉</span>
          <div className='refer-earn'>
            <span className="referral-tag">Refer & earn!</span>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </Link>
      </div>

      <h2>Security</h2>
      <div className="menu-section">
       <div className="menu-item">
        <span>Allow Notifications</span>
         <ToggleSwitch
            checked={isAllowNotificationEnabled}
            onChange={(e) => setAllowNotificationEnabled(e.target.checked)}
         />
        </div>
        <a href="more/change-pin" className="menu-item">
          <span> Change Rise PIN</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </a>
        <a href="more/reset-password" className="menu-item">
          <span> Password Reset</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </a>
      </div>

      <h2>About Rise</h2>
      <div className="menu-section">
        <a href="https://risevest.com/terms-of-use" target="_blank" rel="noreferrer" className="menu-item">
          <span> Terms & Conditions</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </a>
        <a href="https://risevest.com/privacy-policy" target="_blank" rel="noreferrer" className="menu-item">
          <span> Privacy Policy</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </a>
        <a href="https://risevest.com/faq" target="_blank" rel="noreferrer" className="menu-item">
          <span> FAQs</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </a>
        <a href="/account/contact-us" className="menu-item">
          <span> Contact Us</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </a>
        <a href="https://risevest.com/blog" target="_blank" rel="noreferrer" className="menu-item">
          <span> Visit Our Blog</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </a>
        <a href="https://risevest.com" target="_blank" rel="noreferrer" className="menu-item">
          <span> Visit Our Website</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </a>
        <a href="https://bit.ly/RiseInvestmentClub" target="_blank" rel="noreferrer" className="menu-item">
          <span> Join Our Community</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </a>
      </div>
    </div>
  );
};

export default MorePage;