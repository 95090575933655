import React from 'react';
import './AuthorizationCharge.css';
import Header from '../PageHeader/Header';
import Button from '../Buttons/Button/Button';

const AuthorizationCharge = () => {


  return (
        <div className="content-wrapper">
          <div className="content-inner">
            <div className="grid-container">
              <div className="authorization-content">
            
                <Header 
                    title='Authorization Charge'
                    className='header'
                />
                
                <p className="description">
                  We'll charge a small amount to your card to verify it. 
                  We'll add the amount to your Rise Wallet.
                </p>

                <Button
                  className="charge-button"
                >
                  Add ₦ 100
                </Button>
              </div>
            </div>
          </div>
        </div>
  );
};

export default AuthorizationCharge;