import React from 'react';
import { ChevronRight } from 'lucide-react';
import './ClickableProfileField.css';

const ClickableProfileField = ({ label, value, onClick }) => {
  return (
    <div className="profile-field-clickable" onClick={onClick}>
      <p className="field-label">{label}</p>
      <div className="field-value">
        <p>{value}</p>
        <ChevronRight />
      </div>
    </div>
  );
};

export default ClickableProfileField;