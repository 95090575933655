import React from 'react';
import './IDVerification.css';
import Header from '../PageHeader/Header';
import ArrowRightIcon from '../Icons/ArrowRightIcon';

const IDVerification = () => {
 
  return (
    <div className="page-container">
      <div className="page-content">
        
        <Header
          title="ID Verification"
          className='page-header2'
        />
        
        <div className="content-wrapper2">
          <p className="description">
            The Security and Exchanges Commission(SEC) requires that we verify a valid means of identification
          </p>
          
          <div className="verification-list2">
            <a 
              className="verification-item" 
              href="/account/verification/email" 
              aria-disabled="true"
            >
              <span>Email Verification</span>
              <div className="status-wrapper">
                <div className="status-chip success">
                  <span>Verified</span>
                </div>
                <ArrowRightIcon />
              </div>
            </a>
            
            <a 
              className="verification-item"
              href="/account/verification/country" 
              aria-disabled="true"
            >
              <span>ID Document</span>
              <div className="status-wrapper">
                <div className="status-chip success">
                  <span>Verified</span>
                </div>
                <ArrowRightIcon />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IDVerification;