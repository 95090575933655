import React from "react";

const CircleIcon = () => (
  <svg
    className="icon-small"
    focusable="false"
    aria-hidden="true"
    viewBox="0 0 24 24"
  >
    <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2"></path>
  </svg>
);

export default CircleIcon;
