import React, { useState } from 'react';
import './AccountStatement.css';
import { ArrowDownIcon } from '../Icons/ArrowDownIcon';
import Header from '../PageHeader/Header';
import CalendarIcon from '../Icons/CalendarIcon';
import Modal from './Modal/Modal';

const AccountStatement = () => {
  const [fromDate, setFromDate] = useState('25-11-2024');
  const [toDate, setToDate] = useState('25-11-2024');
  const [error, setError] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedPlans, setSelectedPlans] = useState([]);
  
  const plans = ["All Plans", "nkjjkjkjk", "Emmanuel", "Remade", "Test", "EmmaG Risking It"];

  const togglePlan = (plan) => {
  if (plan === "All Plans") {
    if (selectedPlans.includes("All Plans")) {
      // Uncheck "All Plans" and clear all selections
      setSelectedPlans([]);
    } else {
      // Select "All Plans" and add all items
      setSelectedPlans(plans);
    }
  } else {
    // Toggle individual plans
    setSelectedPlans((prevSelected) =>
      prevSelected.includes(plan)
        ? prevSelected.filter((p) => p !== plan)
        : [...prevSelected, plan]
    );
  }
};


  const openModal = () => setIsModalVisible(true);
  const closeModal = () => setIsModalVisible(false);

  const handleConfirm = () => {
    closeModal();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="account-statement">
      <Header title="Account Statement" className="page-header" />

      <div className="content">
        <form onSubmit={handleSubmit}>
          <button type="button" className="plans-selector" onClick={openModal}>
            <span>{selectedPlans.length} plans selected</span>
            <ArrowDownIcon />
          </button>

          <Modal
            isVisible={isModalVisible}
            onClose={closeModal}
            plans={plans}
            selectedPlans={selectedPlans}
            onPlanToggle={togglePlan}
            onConfirm={handleConfirm}
          />

          <div className="selected-plans">
            {selectedPlans.map((plan) => (
              <span key={plan} className="plan-tag">
                {plan}
              </span>
            ))}
          </div>

          <div className="form-field">
            <div className="date-input">
              <input
                id="fromDate"
                type="text"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                placeholder="DD-MM-YYYY"
              />
              <label htmlFor="fromDate">From this date</label>
              <button type="button" className="calendar-button">
                <CalendarIcon />
              </button>
            </div>
          </div>

          <div className="form-field">
            <div className="date-input">
              <input
                id="toDate"
                type="text"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                placeholder="DD-MM-YYYY"
              />
              <label htmlFor="toDate">To this date</label>
              <button type="button" className="calendar-button">
                <CalendarIcon />
              </button>
            </div>
          </div>

          <p className="email-notice">
            Your statement will be forwarded to your email address a************o@*****.com in a few minutes.
          </p>

          <button type="submit" className="submit-button">
            Request Statement
          </button>

          {error && (
            <div className="error-alert" role="alert">
              {error}
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default AccountStatement;
