import React from "react";

const CloseIcon2 = ({ onClick }) => {
  return (
    <button
      className="close-button"
      tabIndex="0"
      type="button"
      onClick={onClick}
    >
      <span className="sr-only">Close</span>
      <svg
        className="close-icon"
        focusable="false"
        aria-hidden="true"
        viewBox="0 0 24 24"
      >
        <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
      </svg>
    </button>
  );
};

export default CloseIcon2;
