import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MoreVertical } from 'lucide-react';
import './PageHeader.css';
import BackArrowIcon from '../Icons/BackArrowIcon';
import { Button } from 'antd';
import OptionsModal from '../OptionalModal/OptionalModal';

const PageHeader = ({ 
  title, 
  subtitle, 
  showOptions = false,
  className = '',
  titleClassName = '',
  backgroundImage = ''
}) => {
  const navigate = useNavigate();
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  const handleBack = () => {
    navigate(-1);
  };

  const handleOptionsClick = () => {
    setIsOptionsOpen(true);
  };

  const handleCloseOptions = () => {
    setIsOptionsOpen(false);
  };

  const headerStyle = backgroundImage ? {
    backgroundImage: `url(${backgroundImage})`,
  } : {};

  return (
    <>
      <header 
        className={`page-header ${backgroundImage ? 'with-background' : ''} ${className}`}
        style={headerStyle}
      >
        <Button 
          type="text"
          className="back-button"
          onClick={handleBack}
        >
          <BackArrowIcon />
          <span className="sr-only">Go back</span>
        </Button>

        <div className="title-container">
          <h2 className={`page-title ${titleClassName}`}>
            {title}
          </h2>
          {subtitle && (
            <p className="subtitle">{subtitle}</p>
          )}
        </div>

        {showOptions && (
          <Button 
            type="text"
            className="options-button"
            onClick={handleOptionsClick}
          >
            <MoreVertical />
            <span className="sr-only">Open options menu</span>
          </Button>
        )}
      </header>

      {isOptionsOpen && (
        <div className="modal-overlay">
          <OptionsModal onClose={handleCloseOptions} />
        </div>
      )}
    </>
  );
};

export default PageHeader;