import React, { useRef } from "react";
import "./OTPInput.css";

const OTPInput = ({ length = 6, onChange }) => {
  const inputsRef = useRef([]);

  const handleChange = (e, idx) => {
    const value = e.target.value;

    
    if (!/^\d$/.test(value)) {
      e.target.value = ""; 
      return;
    }

    if (onChange) {
      onChange(value, idx);
    }

    if (value && idx < length - 1) {
      inputsRef.current[idx + 1].focus();
    }
  };

  const handleKeyDown = (e, idx) => {
    if (e.key === "Backspace" && !e.target.value && idx > 0) {
      inputsRef.current[idx - 1].focus();
    }
  };

  return (
    <div className="otp-inputs">
      {Array.from({ length }).map((_, idx) => (
        <input
          key={idx}
          ref={(el) => (inputsRef.current[idx] = el)}
          className="otp-input"
          type="tel"
          maxLength="1"
          aria-label={`Digit ${idx + 1}`}
          autoComplete="off"
          onChange={(e) => handleChange(e, idx)}
          onKeyDown={(e) => handleKeyDown(e, idx)}
        />
      ))}
    </div>
  );
};

export default OTPInput;
