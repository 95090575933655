import React from 'react';
import './CurrencyInput.css';

const CurrencyInput = ({ 
  symbol,
  value,
  onChange,
  id,
  error,
  className = ''
}) => {
  const inputWrapperClass = `currency-input ${error ? 'currency-input--error' : ''} ${className}`.trim();

  return (
    <div className={inputWrapperClass}>
      <label htmlFor={id} className="currency-input__label">
        {symbol}
      </label>
      <input
        type="text"
        id={id}
        className="currency-input__field"
        inputMode="numeric"
        autoComplete="off"
        value={value}
        onChange={onChange}
      />
      {error && <span className="currency-input__error">{error}</span>}
    </div>
  );
};

export default CurrencyInput;
