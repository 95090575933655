import React, { useEffect, useState } from 'react';
import './AutoInvest.css';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import Header from '../PageHeader/Header';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowRightIcon from '../Icons/KeyboardArrowRightIcon';

const AutoInvest = () => {
  const navigate = useNavigate();
  const [isAutoInvestEnabled, setIsAutoInvestEnabled] = useState(false);
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    const savedAmount = localStorage.getItem('autoInvestAmount');
    if (savedAmount) {
      setAmount(parseFloat(savedAmount));
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Submit');
  };

  const handleAmountEdit = () => {
    navigate(`tap-to-edit?amount=${amount}`);
  };

  return (
    <div className="auto-invest-container">
      <div className="auto-invest-wrapper">
        <form className="auto-invest-form" onSubmit={handleSubmit}>
          <div className="auto-invest-content">
            <Header title="Auto-invest" />

            <div className="auto-invest-body">
              <button 
                type="button" 
                className="amount-button"
                onClick={handleAmountEdit}
              >
                <p className="amount">${amount.toFixed(2)}</p>
                <p className="amount-hint">Tap to edit</p>
              </button>

              <p className="amount-description">
                This amount will be automatically charged to your Rise Wallet.
              </p>

              <div className="settings-container">
                <div className="setting-item">
                  <span>Auto-Invest</span>
                  <ToggleSwitch
                    checked={isAutoInvestEnabled}
                    onChange={(e) => setIsAutoInvestEnabled(e.target.checked)}
                  />
                </div>

                <button 
                  type="button" 
                  className="frequency-button"
                  disabled={!isAutoInvestEnabled}
                >
                  <div className="frequency-content">
                    <p>Auto-invest frequency</p>
                    <p className="frequency-value"></p>
                  </div>
                  <KeyboardArrowRightIcon />
                </button>
              </div>

              <button 
                type="submit" 
                className="submit-button"
                disabled={!isAutoInvestEnabled}
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AutoInvest;
