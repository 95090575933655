import React, { useState } from 'react';
import './WalletInterest.css';
import Header from '../PageHeader/Header';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';

const WalletInterest = () => {
  const [isInterestEnabled, setIsInterestEnabled] = useState(true);

  
  return (
    <div 
      aria-hidden="false" 
      className="wallet-interest-container"
    >
      
      <Header
        title="Wallet Interest"
        className='page-header'
      />

      <div className="content-container">
        <p className="description">
          This is for users who do not want to earn interest on their wallet balance. 
          Turning this off will result in a loss of already earned interest on the wallet.
        </p>

        <div className="toggle-container">
          <span>Wallet Interest</span>
          <div className="switch-wrapper">
            <ToggleSwitch
            checked={isInterestEnabled}
            onChange={() => setIsInterestEnabled(!isInterestEnabled)}
            />
          </div>

          
        </div>
      </div>
    </div>
  );
};

export default WalletInterest;