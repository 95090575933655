import React from 'react';
import './Button.css';

const Button = ({
  children,
  type = 'button',
  disabled = false,
  onClick, 
  className = '', 
  variant = 'default', 
  fullWidth = false, 
  dataTestId, 
  icon: Icon 
}) => {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      data-test-id={dataTestId}
      className={`button ${variant} ${fullWidth ? 'full-width' : ''} ${className}`}
    >
      {Icon && (
        <div className="icon-container">
          <Icon />
        </div>
      )}
      <span>{children}</span>
    </button>
  );
};

export default Button;
