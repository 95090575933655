import { useState } from 'react';
import './NextOfKin.css';
import { COUNTRIES, BANKS, RELATIONSHIPS } from './Constants';
import Header from '../../PageHeader/Header';

export default function NextOfKin() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    countryCode: '+234',
    relationship: '',
    bankName: '',
    accountNumber: ''
  });
  
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = (data) => {
    const errors = {};

    if (!data.firstName.trim()) errors.firstName = 'First name is required';
    if (!data.lastName.trim()) errors.lastName = 'Last name is required';
    
    if (!data.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = 'Invalid email format';
    }

    if (!data.phoneNumber.trim()) {
      errors.phoneNumber = 'Phone number is required';
    } else if (!/^\d{6,}$/.test(data.phoneNumber.replace(/\s/g, ''))) {
      errors.phoneNumber = 'Invalid phone number';
    }

    if (!data.relationship) errors.relationship = 'Relationship is required';
    if (!data.bankName) errors.bankName = 'Bank name is required';
    
    if (!data.accountNumber.trim()) {
      errors.accountNumber = 'Account number is required';
    } else if (!/^\d{10}$/.test(data.accountNumber)) {
      errors.accountNumber = 'Account number must be 10 digits';
    }

    return errors;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    setFormData(prev => ({
      ...prev,
      [name]: name === 'phoneNumber' ? value.replace(/\D/g, '') : value
    }));

    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formErrors = validateForm(formData);
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      setIsSubmitting(false);
      return;
    }

    try {
      
      await new Promise(resolve => setTimeout(resolve, 1000));
      console.log('Form submitted:', formData);
      
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        countryCode: '+234',
        relationship: '',
        bankName: '',
        accountNumber: ''
      });
      setErrors({});
      alert('Next of kin details saved successfully!');
    } catch (error) {
      console.error('Error submitting form:', error);
      setErrors({ submit: 'Failed to save next of kin details. Please try again.' });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="next-of-kin">
      <Header title="Next of kin" />

      <form onSubmit={handleSubmit} noValidate>
        <div className="form-field">
          <input
            id="firstName"
            name="firstName"
            type="text"
            value={formData.firstName}
            onChange={handleInputChange}
            placeholder=" "
          />
          <label htmlFor="firstName" className="form-label">First Name</label>
          {errors.firstName && <span className="error-message">{errors.firstName}</span>}
        </div>

        <div className="form-field">
          <input
            id="lastName"
            name="lastName"
            type="text"
            value={formData.lastName}
            onChange={handleInputChange}
            placeholder=" "
          />
          <label htmlFor="lastName" className="form-label">Last Name</label>
          {errors.lastName && <span className="error-message">{errors.lastName}</span>}
        </div>

        <div className="form-field">
          <input
            id="email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder=" "
          />
          <label htmlFor="email" className="form-label">Email Address</label>
          {errors.email && <span className="error-message">{errors.email}</span>}
        </div>

        <div className="form-field phone-field">
          <div className="phone-input">
            <select
              name="countryCode"
              value={formData.countryCode}
              onChange={handleInputChange}
              className="country-code"
            >
              {COUNTRIES.map(country => (
                <option key={country.code} value={country.dialCode}>
                  {country.dialCode}
                </option>
              ))}
            </select>
            <div className="phone-input-wrapper">
              <input
                id="phoneNumber"
                name="phoneNumber"
                type="tel"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                placeholder=" "
              />
              <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
            </div>
          </div>
          {errors.phoneNumber && <span className="error-message">{errors.phoneNumber}</span>}
        </div>

        <div className="form-field">
          <select
            id="relationship"
            name="relationship"
            value={formData.relationship}
            onChange={handleInputChange}
            className={!formData.relationship ? 'empty' : ''}
          >
            <option value="" disabled></option>
            {RELATIONSHIPS.map(relation => (
              <option key={relation} value={relation}>{relation}</option>
            ))}
          </select>
          <label htmlFor="relationship" className="form-label">Relationship</label>
          {errors.relationship && <span className="error-message">{errors.relationship}</span>}
        </div>

        <div className="form-field">
          <select
            id="bankName"
            name="bankName"
            value={formData.bankName}
            onChange={handleInputChange}
            className={!formData.bankName ? 'empty' : ''}
          >
            <option value="" disabled></option>
            {BANKS.map(bank => (
              <option key={bank.id} value={bank.name}>{bank.name}</option>
            ))}
          </select>
          <label htmlFor="bankName" className="form-label">Select a Bank Name</label>
          {errors.bankName && <span className="error-message">{errors.bankName}</span>}
        </div>

        <div className="form-field">
          <input
            id="accountNumber"
            name="accountNumber"
            type="text"
            value={formData.accountNumber}
            onChange={handleInputChange}
            placeholder=" "
            maxLength={10}
          />
          <label htmlFor="accountNumber" className="form-label">Account Number</label>
          {errors.accountNumber && <span className="error-message">{errors.accountNumber}</span>}
        </div>

        {errors.submit && <div className="error-message submit-error">{errors.submit}</div>}

        <button 
          type="submit" 
          className="submit-button" 
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Saving...' : 'Save'}
        </button>
      </form>
    </div>
  );
}