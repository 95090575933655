import React from "react";
import "./ProfileBankAccountDetails.css";
import KeyboardArrowLeftIcon from "../Icons/KeyboardArrowLeftIcon";
import BankIcon from "../Icons/BankIcon";
import Header from "../PageHeader/Header";
import Button from "../Buttons/Button/Button";

const ProfileBankAccountDetails = () => {
  return (
    <div className="bank-account">
      <div className="container">
        
        <Header
            title = 'Bank Account'
            className="header"
        />

        <div className="content">
          <div className="circular-container">
            <BankIcon className="custom-icon" />
          </div>

          <div className="details">
            <h2 className="account-number">1101103627</h2>
            <div className="bank-info">
              <p className="bank-name">Kuda Bank</p>
            </div>
          </div>

          <div className="info-list">
            <div className="info-item">
              <p className="label">Account Name</p>
              <p className="value">ADEOGO EMMANUEL OLADIPO</p>
            </div>
            <div className="info-item">
              <p className="label">Added On</p>
              <p className="value">22 Feb 2023</p>
            </div>
          </div>

          <Button
            type="button"
            className="delete-button"
          >
            Delete bank account
          </Button>

        </div>
      </div>
    </div>
  );
};

export default ProfileBankAccountDetails;
