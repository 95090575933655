import React from 'react';
import { Link } from 'react-router-dom';
import './BanksAndCards.css';
import Header from '../PageHeader/Header';
import CardIcon from '../Icons/CardIcon';
import BankIcon from '../Icons/BankIcon';
import ArrowRightIconPrimary from '../Icons/ArrowRightIconPrimary';

const BanksAndCards = () => {
  return (
    <div className="banks-and-cards">
      
      <Header
        title = 'Accounts and Cards'
        className ='banks-and-cards__header'
      />

      <nav className="banks-and-cards__navigation">
        <Link 
          to="cards" 
          className="banks-and-cards__link"
        >
          <div className="banks-and-cards__link-content">
            <div className="banks-and-cards__link-icon">
              <CardIcon />
            </div>
            <div className="banks-and-cards__link-text">
              <span className="banks-and-cards__link-text-title">Cards</span>
              <span className="banks-and-cards__link-text-subtitle">
                Manage your debit / credit cards
              </span>
            </div>
          </div>
          <div className="banks-and-cards__link-arrow">
            <ArrowRightIconPrimary className="banks-and-cards__link-arrow"/>
          </div>
        </Link>

        <Link 
          to="accounts" 
          className="banks-and-cards__link"
        >
          <div className="banks-and-cards__link-content">
            <div className="banks-and-cards__link-icon">
              <BankIcon />
            </div>
            <div className="banks-and-cards__link-text">
              <span className="banks-and-cards__link-text-title">Bank Accounts</span>
              <span className="banks-and-cards__link-text-subtitle">
                Your bank accounts you can withdraw to
              </span>
            </div>
          </div>
          <div className="banks-and-cards__link-arrow">
            <ArrowRightIconPrimary className="banks-and-cards__link-arrow"/>
          </div>
        </Link>
      </nav>
    </div>
  );
};

export default BanksAndCards;