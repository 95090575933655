import React from "react";
import "./Modal.css";

const Modal = ({ isVisible, onClose, plans, selectedPlans, onPlanToggle, onConfirm }) => {
  if (!isVisible) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <div className="modal-header">
          <button className="modal-close-button" onClick={onClose} aria-label="Close">
            ×
          </button>
          <h2 className="modal-title">Select Plans</h2>
        </div>
        <div className="modal-body">
          {plans.map((plan, index) => (
            <div key={index} className="plan-item">
              <input
                type="checkbox"
                id={`plan-${index}`}
                checked={selectedPlans.includes(plan)}
                onChange={() => onPlanToggle(plan)}
              />
              <label htmlFor={`plan-${index}`}>{plan}</label>
            </div>
          ))}
        </div>
        <div className="modal-footer">
          <button className="confirm-button" onClick={onConfirm}>
            Select ({selectedPlans.length}) plans
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
