import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ChangePhoneNumber.css";
import Header from "../../PageHeader/Header";
import CountrySelect from "../CountrySelect/CountrySelect";
import Button from "../../Buttons/Button/Button";

const ChangePhoneNumber = () => {
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setPhone(value);
    if (!/^\d+$/.test(value)) {
      setError("Please enter a valid phone number.");
    } else {
      setError("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (phone.length >= 10) {
      navigate("verify-phone"); 
    }
  };

  return (
    <div className="container">
      <div className="full-page">
        <div className="content-wrapper">
          <Header title="Change your Phone Number" className="header-title" />
          <div className="form-container">
            <form onSubmit={handleSubmit}>
              <p className="form-description">Please type in your Phone Number</p>
              <div className="input-group">
                <CountrySelect />
                <div className="phone-input">
                  <label htmlFor="phone" className="form-label">
                    Phone Number
                  </label>
                  <input
                    id="phone"
                    type="text"
                    placeholder="Phone Number"
                    className="phone-number"
                    value={phone}
                    onChange={handlePhoneChange}
                  />
                </div>
              </div>
              {error && <p className="error-message">{error}</p>}
            
              <Button
                type="submit" 
                onClick={handleSubmit} 
                className="submit-button" 
                variant="primary" 
                disabled={phone.length < 10 || error !== ""}
              >
               Next
              </Button>

            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePhoneNumber;
