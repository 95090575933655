import React, { useState } from "react";
import "./TransactionHistory.css";
import Header from "../PageHeader/Header";
import PauseIcon from "../Icons/PauseIcon";
import CallReceivedIcon from "../Icons/CallReceivedIcon";

const TransactionHistory = () => {
  const [activeTab, setActiveTab] = useState("All");

  const transactions = [
    { id: "1", description: "Transfer from Card", date: "Nov 20, 2024", amount: "+ $5", type: "Deposit", icon: <PauseIcon /> },
    { id: "2", description: "Transfer from Card", date: "Nov 20, 2024", amount: "+ $1", type: "Deposit", icon: <PauseIcon /> },
    { id: "3", description: "Transfer to Plan", date: "Nov 19, 2024", amount: "+ $0.06", type: "Transfer to Plan", icon: <PauseIcon /> },
    { id: "4", description: "Withdrawal from Wallet", date: "Oct 14, 2024", amount: "$49.48", type: "Withdrawal from Wallet", icon: <CallReceivedIcon /> },
    { id: "5", description: "Withdrawal from Plan", date: "Oct 14, 2024", amount: "- $10.00", type: "Withdrawal from Plan", icon: <PauseIcon /> },
    { id: "6", description: "Transfer from Card", date: "Nov 20, 2024", amount: "+ $5", type: "Deposit", icon: <PauseIcon /> },
    { id: "7", description: "Transfer from Card", date: "Nov 20, 2024", amount: "+ $1", type: "Deposit", icon: <PauseIcon /> },
    { id: "8", description: "Transfer to Plan", date: "Nov 19, 2024", amount: "+ $0.06", type: "Transfer to Plan", icon: <PauseIcon /> },
    { id: "9", description: "Withdrawal from Wallet", date: "Oct 14, 2024", amount: "$49.48", type: "Withdrawal from Wallet", icon: <CallReceivedIcon /> },
    { id: "10", description: "Withdrawal from Plan", date: "Oct 14, 2024", amount: "- $10.00", type: "Withdrawal from Plan", icon: <PauseIcon /> },
  ];

  const tabs = [
    { label: "All", filter: "All" },
    { label: "Deposits", filter: "Deposit" },
    { label: "Transfer to plan", filter: "Transfer to Plan" },
    { label: "Withdrawal from plan", filter: "Withdrawal from Plan" },
    { label: "Withdrawal from wallet", filter: "Withdrawal from Wallet" },
  ];

  const filteredTransactions =
    activeTab === "All"
      ? transactions
      : transactions.filter((transaction) => transaction.type === activeTab);

  return (
    <div className="transaction-history">
      <div className="container">
        <Header title="Transaction History" className="header" />

        <div className="tabs sticky-tabs">
          {tabs.map((tab) => (
            <button
              key={tab.label}
              className={`tab ${activeTab === tab.filter ? "active" : ""}`}
              onClick={() => setActiveTab(tab.filter)}
            >
              {tab.label}
            </button>
          ))}
        </div>

        <div className="transactions">
          {filteredTransactions.map((transaction) => (
            <a
              key={transaction.id}
              href={`/account/transactions/${transaction.id}`}
              className="transaction-item"
            >
              <div className="icon-container">{transaction.icon}</div>
              <div className="transaction-details">
                <div className="info">
                  <p className="description">{transaction.description}</p>
                  <p className="date">{transaction.date}</p>
                </div>
                <div className="amount">{transaction.amount}</div>
              </div>
            </a>
          ))}
          {filteredTransactions.length === 0 && (
            <p className="no-transactions">No transactions to display.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default TransactionHistory;
