import React, { useEffect } from 'react';
import './AffiliateReferrals.css';
import { useNavigate, useLocation } from 'react-router-dom';
import EmptyCommissionIcon from '../Icons/EmptyCommissionIcon';
import Header from '../PageHeader/Header';

const AffiliateReferrals = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  useEffect(() => {
    if (location.pathname.startsWith('/refer/faq')) {
      navigate('refer/faq');
    }
  }, [location.pathname, navigate]);

  return (
    <div className="affiliate-referrals">
      <Header
        title="Affiliate & Referals"
        className='page-header'
      />
      <div className="subtitle">
        <small>Become an Affiliate and earn up to <span className="highlight">3% commission!</span></small>
      </div>
      <div className="content">
        <div className="referral-card">
          <div className="total-referrals">
            <h2>0</h2>
            <p>TOTAL REFERRALS</p>
          </div>
          <div className="stats">
            <div className="stat-row">
              <p>FUNDED REFERRALS</p>
              <p className="value">0</p>
            </div>
            <div className="stat-row">
              <div className="label-with-info">
                <p>LOCKED EARNINGS</p>
                <button className="info-button" aria-label="More information">ⓘ</button>
              </div>
              <p className="value">$0.00</p>
            </div>
            <div className="stat-row">
              <p>TOTAL EARNINGS</p>
              <p className="value">$0.00</p>
            </div>
          </div>
        </div>
        
        <div className="start-earning">
          <hr className='hr2'/>
          <p>START EARNING</p>
          <div className="referral-info">
            <div className="referral-code">
              <div>
                <p>Your Referral Code</p>
                <p className="code">QMTNGD9G</p>
              </div>
              <button className="copy-button">Copy code</button>
            </div>
            <div className="referral-link">
              <div>
                <p>Your Referral link</p>
                <a href="https://risevest.com/ref/QMTNGD9G" target="_blank" rel="noreferrer">
                  https://risevest.com/ref/QMTNGD9G
                </a>
              </div>
              <button className="copy-button">Copy link</button>
            </div>
          </div>
          <hr />
          <a href="refer/faq" className="faq-link">
            <p>Have questions? Read our FAQs</p>
            <span className="arrow-right">➔</span>
          </a>
          <hr />
           <hr />
          <a href="refer/referral" className="faq-link">
            <p>Referred by someone? Enter their code</p>
            <span className="arrow-right">➔</span>
          </a>
          <hr />
        </div>
        
        <section className="commission-history">
          <h2>Commission history</h2>
          <div className="empty-state">
            <div className="icon">
              <EmptyCommissionIcon />
            </div>
            <p>Your commissions will appear here</p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AffiliateReferrals;