import React, { useState } from 'react';

import './PersonalizeForm.css';
import Header from '../../PageHeader/Header';
import ChevronRightIcon from '../../Icons/ChevronRightIcon';
import ErrorIcon from '../../Icons/ErrorIcon';
import { useNavigate } from 'react-router-dom';
import Button from '../../Buttons/Button/Button';

const PersonalizeForm = () => {
  const [planName, setPlanName] = useState('');
  const [backgroundImage, setBackgroundImage] = useState('https://res.cloudinary.com/dsjxfieim/image/upload/v1634304182/Plan%20Covers/Untitled_1_1_ysepko.jpg');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBackgroundImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCancel = () => {
    setPlanName('');
    setBackgroundImage('');
    setError('');
    navigate(-1);
    console.log('Form reset');
  };

  return (
    <form className="personalize-form" onSubmit={handleSubmit}>
      <Header title="Personalize" className="form-header" />

      <div className="form-field">
        <label
          htmlFor="name"
          className={`floating-label ${planName ? 'active' : ''}`}
        >
          Plan name
        </label>
        <input
          id="name"
          type="text"
          maxLength={15}
          value={planName}
          onChange={(e) => setPlanName(e.target.value)}
        />
      </div>

      <div className="image-upload">
        <p className="image-label" id="change-plan-image-label">
          <span>Change plan image</span>
          <ChevronRightIcon />
        </p>
        <div
          className="image-preview"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        />
        <input
          type="file"
          id="change-plan-image"
          name="id-image"
          accept="image/*"
          onChange={handleImageChange}
          aria-labelledby="change-plan-image-label"
        />
      </div>

      {error && (
        <div className="error-alert" role="alert">
          <ErrorIcon />
          <div className="error-message">{error}</div>
        </div>
      )}

      <Button className="submit-button" onClick={handleSubmit}>
        Save Changes
      </Button>

      <Button onClick={handleCancel} className="cancel-button">
        Cancel
      </Button>
    </form>
  );
};

export default PersonalizeForm;
