import React, { useState } from 'react';
import { Slider } from 'antd';
import Header from '../../PageHeader/Header';
import './AdditionalInfo.css';
import { useNavigate } from 'react-router-dom';
import Button from '../../Buttons/Button/Button';

const AdditionalInfo = ({ onSubmit, onBack }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    gender: '',
    monthlyIncome: 0,
    employmentStatus: '',
    jobSector: '',
    maritalStatus: ''
  });

  const incomeMarks = {
    0: '<₦50k',
    25: '₦50k - ₦100k',
    50: '₦100k - ₦350k',
    75: '₦350k - ₦500k',
    100: '₦500k+'
  };

  const genderOptions = ['Female', 'Male', 'Prefer not to say'];
  
  const employmentOptions = [
    'Self Employed',
    'Gov-sector Employed',
    'Private Sector Employed',
    'Unemployed',
    'Retired'
  ];

  const jobSectors = [
    'Agriculture',
    'Technology',
    'Healthcare',
    'Education',
    'Finance',
    'Manufacturing',
    'Services',
    'Other'
  ];

  const maritalStatuses = [
    'Single',
    'Married',
    'Divorced',
    'Widowed',
    'Prefer not to say'
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate('next');
  };

  return (
    <div className="form-page">
      <Header title="Additional Information" />

      <p className="intro-text">
        We need this to provide you with a more customised experience.<br/>
        Filling this out is entirely optional.
      </p>

      <form onSubmit={handleSubmit}>
        <div className="section">
          <h3>Gender</h3>
          <div className="radio-group">
            {genderOptions.map(option => (
              <label key={option} className="radio-label">
                <input
                  type="radio"
                  value={option}
                  checked={formData.gender === option}
                  onChange={e => setFormData({...formData, gender: e.target.value})}
                  name="gender"
                />
                <span>{option}</span>
              </label>
            ))}
          </div>
        </div>

        <div className="section">
          <h3>Monthly Income</h3>
          <div className="slider-container">
            <Slider
              marks={incomeMarks}
              step={25}
              defaultValue={0}
              value={formData.monthlyIncome}
              onChange={(value) => setFormData({...formData, monthlyIncome: value})}
              trackStyle={{ backgroundColor: 'var(--action-primary)' }}
              handleStyle={{
                backgroundColor: 'var(--action-primary)'
              }}
              railStyle={{ backgroundColor: '#e2e8f0' }}
            />
          </div>
        </div>

        <div className="section">
          <h3>Employment Status</h3>
          <div className="employment-options">
            {employmentOptions.map(option => (
              <label key={option} className="radio-label">
                <input
                  type="radio"
                  value={option}
                  checked={formData.employmentStatus === option}
                  onChange={e => setFormData({...formData, employmentStatus: e.target.value})}
                  name="employment"
                />
                <span>{option}</span>
              </label>
            ))}
          </div>
        </div>

        <div className="section">
          <h3>Job Sector</h3>
          <select
            value={formData.jobSector}
            onChange={e => setFormData({...formData, jobSector: e.target.value})}
          >
            <option value="">Select a sector</option>
            {jobSectors.map(sector => (
              <option key={sector} value={sector}>{sector}</option>
            ))}
          </select>
        </div>

        <div className="section">
          <h3>Marital Status</h3>
          <select
            value={formData.maritalStatus}
            onChange={e => setFormData({...formData, maritalStatus: e.target.value})}
          >
            <option value="">Select status</option>
            {maritalStatuses.map(status => (
              <option key={status} value={status}>{status}</option>
            ))}
          </select>
        </div>

        <Button
          type="button" 
          onClick={handleSubmit} 
          className="primary"
        >
          Continue
        </Button>

      </form>
    </div>
  );
};

export default AdditionalInfo;