import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ConfirmWithPassword.css";
import Header from "../../PageHeader/Header";
import ErrorIcon from "../../Icons/ErrorIcon";
import VisibilityIcon from "../../Icons/VisibilityIcon";
import Button from "../../Buttons/Button/Button";

const ConfirmWithPassword = () => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    if (value && value.length < 11) {
      setError("Password must be at least 11 characters long.");
    } else {
      setError("");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password.length >= 11) {
      navigate("enter-phone-number");
    }
  };

  const isButtonEnabled = password.length >= 11;

  return (
    <div className="container">
      <div className="full-page">
        <div className="content-wrapper">
          <Header title="Confirm With Password" className="header-title" />
          <div className="form-container">
            <form onSubmit={handleSubmit}>
              <div className="form-control">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <div className="input-wrapper">
                  <input
                    id="password"
                    type={showPassword ? "text" : "password"}
                    className="password-input"
                    placeholder="Enter your password"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  <button
                    type="button"
                    className="visibility-button"
                    aria-label="Show Password"
                    onClick={togglePasswordVisibility}
                  >
                    <VisibilityIcon />
                  </button>
                </div>
              </div>
              {error && (
                <div className="alert-container" role="alert">
                  <div className="alert-icon">
                    <ErrorIcon />
                  </div>
                  <span className="alert-message">{error}</span>
                </div>
              )}
              
              <Button
                type="submit" 
                onClick={handleSubmit} 
                variant="primary" 
                className="submit-button" 
                disabled={!isButtonEnabled}
              >
                Change Phone Number
              </Button>

            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmWithPassword;
