import React from "react";
import "./ErrorAlert.css";
import ErrorIcon from "../Icons/ErrorIcon";


const ErrorAlert = ({ message }) => {
  return (
    <div className="alert-container" role="alert">
      <div className="alert-icon">
        <ErrorIcon />
      </div>
      <span className="alert-message">{message}</span>
    </div>
  );
};

export default ErrorAlert;
