const EmailIcon = ({ className }) => (
  <svg
    width="1.22em"
    height="1em"
    viewBox="0 0 33 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    className={className} // Pass the className here
  >
    <path
      d="M27.8522 9.19485C30.1752 9.19485 32.0996 7.23604 32.0996 4.85447C32.0996 2.4729 30.1752 0.5 27.8522 0.5C25.5292 0.5 23.6048 2.4729 23.6048 4.85447C23.6048 7.23604 25.5292 9.19485 27.8522 9.19485ZM3.81095 26.5H25.7353C28.0721 26.5 29.3505 25.1612 29.3505 22.7092V10.2377C29.0343 10.3363 28.7044 10.3927 28.347 10.435V22.681C28.347 23.1883 28.2783 23.6252 28.1271 24.0057L19.0687 15.0854L24.7182 9.43442C24.4433 9.23713 24.1821 9.01165 23.9484 8.75799L16.5257 16.2127C15.9347 16.8187 15.3161 17.0442 14.8075 17.0442C14.3127 17.0442 13.6666 16.8046 13.103 16.2127L1.88655 4.95312C2.34016 4.54444 2.98621 4.33306 3.8247 4.33306H22.4089C22.4364 3.98076 22.4914 3.64255 22.6013 3.31843H3.72848C1.37796 3.31843 0.0996094 4.61491 0.0996094 7.10921V22.7092C0.0996094 25.1894 1.37796 26.5 3.81095 26.5ZM1.10305 22.681V7.1374C1.10305 6.63008 1.17177 6.19322 1.30923 5.82683L10.5601 15.0854L1.36421 24.1325C1.18552 23.7238 1.10305 23.2447 1.10305 22.681ZM14.8075 18.0729C15.5498 18.0729 16.3608 17.777 17.1718 16.9737L18.3539 15.79L27.536 24.8653C27.0687 25.2599 26.4364 25.4713 25.6254 25.4713H3.8247C3.05494 25.4713 2.45012 25.2881 1.99652 24.9499L11.2749 15.79L12.457 16.9737C13.268 17.777 14.079 18.0729 14.8075 18.0729Z"
      fill="currentColor"
    />
  </svg>
);

export default EmailIcon;
